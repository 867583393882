import Vue from 'vue'
import Vuex from 'vuex'

import {getField, updateField} from 'vuex-map-fields'

Vue.use(Vuex)
export const store = new Vuex.Store({
    state:{
        //general
        currentNav:'front',
        username:'',
        oldpassword:'',
        loggedInUser:{
            id:0,
            name:'',
        },
        loggedIn:false,
        badgeData:{},
        batchSize:25,
        logoOverride:''
    },
    mutations:{
        updateField,
    },
    getters:{
        getField,
    },
    actions:{

    }
})