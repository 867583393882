<template>
  <div >
        <div  class="validationDiv">
            <header>
                <div class="filterVar">
                    <div class="horizFlex">
                         <img alt="school logo" :src="logoPath"  class="vallogo" v-if="logoPath!=''">
                        <label class="titleText">Remote Capture Validation</label>
                    </div>
                   
                    <div class="vertFlex rightjustified">
                        <label class="textmedium">{{loggedInUser.Name}}</label>
                        <linkbutton toplabel="Logout" @click="loggedIn=false" class="textmedium"/>
                     </div>
                </div>
            </header>
            <div class="mainDiv">
                <!-- filters -->
                <div class="filterVar">
                    <div class="vertFlexLeft">
                        <label class="lighttext">{{batchName}}</label>
                        <div class="horizFlex mediumtext">
                            <label class="lighttext marginright"># in Batch: {{counts.all}}</label>
                            <label class="lighttext marginright "> (# Total: {{totalToReview}})</label>
                            <linkbutton  toplabel="Reset" @click="resetBatch()" class="lighttext"/>
                        </div>
                        
                    </div>
                        
                    <div>
                        <button v-bind:class="ButtonFilterClass('in-all')"  @click="setFilter('in-all')">
                           <label for="">All</label>
                       </button>
                       <button v-bind:class="ButtonFilterClass('in-pass')" @click="setFilter('in-pass')">
                            <label for="">{{counts.inpass}}</label>
                        </button>
                       
                        <button v-bind:class="ButtonFilterClass('in-maybe')" @click="setFilter('in-maybe')">
                            <label for="">{{counts.inmaybe}}</label>
                        </button>
                        <button v-bind:class="ButtonFilterClass('in-fail')"  @click="setFilter('in-fail')">
                           <label for="">{{counts.infail}}</label>
                       </button>
                    </div>

                    <div>
                        <button class="buttonFilterWide" @click="askPass()" >
                            <div class="horizFlex">
                                <img src="@/assets/CheckmarkGreen.svg" alt="" class="buttonImage">
                                <p class="actionbuttontext">{{passAllText}} ({{filteredRecords.length}}) </p>
                            </div>
                        </button>
                        <button class="buttonFilterWide" @click="askFail()" >
                            <div class="horizFlex">
                                <img src="@/assets/RejectRed.svg" alt="" class="buttonImage">
                                <p class="actionbuttontext">{{failAllText}} ({{filteredRecords.length}})</p>
                            </div>
                        </button>
                    </div>
                    
                     
                </div>

                <div v-if="pleaseWait">
                    <label class="pleasewait">Please Wait...</label>
                </div>
                
                <!-- record -->
                <div :class="scrollBoxDiv">
                    <div v-bind:class="recordClass(record)" v-for="record in filteredRecords" :key="record.id">
                        <div class="vertFlexLeft">
                            <p class="recordText bold">{{record.name}}</p>
                            <p class="recordText bold">{{record.badgeNumber}}</p>
                            <!-- <p class="recordTextsmall" v-if="record.badgeNumber!=''"># {{record.badgeNumber}}</p>  -->
                            <p class="recordTextsmall">{{record.badgeType}}</p>
                            <p class="recordText bold">Score: {{record.combinedScore}}</p>
                            <!-- <p class="recordTextsmall">{{record.locationName}}</p> -->
                        </div>

                        <!-- images -->
                        <div class="horizFlex" v-for="image in record.imageList" :key="image.caption">
                            <div class="vertFlex imageAndCaption">
                                <img  :src="getImage(image)" alt="" :class="recordImageClass(image)" @click="toggleImageSize(image,$event.currentTarget,record)">
                                <p class="imageCaption">
                                    {{image.badgeImageTypeName}}
                                </p>
                                <button v-if="image.enlarged" @click="$refs.imgedit.openModal()">Edit</button>
                            </div>
                        </div>

                        <!-- actions -->
                        <div class="vertFlex">
                            <!-- <div class="horizFlex">
                                <div class="bar greenbar" v-if="record.instatus=='pass'">
                                    <label for="">{{record.combinedScore}}</label>
                                </div>
                                <div class="bar yellowbar" v-if="record.instatus=='maybe'">
                                    <label for="">{{record.combinedScore}}</label>
                                </div>
                                <div class="bar redbar" v-if="record.instatus=='fail'">
                                    <label for="">{{record.combinedScore}}</label>
                                </div>
                            </div> -->
                            <div >
                                <reject-button :record=record :reasons="rejectReasons" @setstatus="setStatus()" />
                            </div>
                        </div>       
                    </div>
                </div>

                <!-- summary and zoom -->
                <div class="filterVar">
                    <div class="horizFlex">
                        <button :class="buttonActionNarrowClass(1)" @click="setzoom(1)">
                            <img class="colimg" src="@/assets/one column.svg"/>
                        </button>
                        <button :class="buttonActionNarrowClass(2)" @click="setzoom(2)">
                             <img class="colimg" src="@/assets/two columns.svg"/>
                        </button>
                        <!-- <button :class="buttonActionNarrowClass(3)" @click="setzoom(3)">3</button>
                        <button :class="buttonActionNarrowClass(4)" @click="setzoom(4)">4</button> -->
                        <button class="buttonAction marginleft" @click="zoomOut()">{{zoomOutLabel}}</button>
                        <button class="buttonAction" @click="zoomIn()">{{zoomInLabel}}</button>
                    </div>

                    <div class="horizFlex margin10">
                        <label class="totallabel">Pass: {{counts.outpass}} | </label>
                        <label class="totallabel">Pending: {{counts.toreview}} |</label>
                        <label class="totallabel">Fail: {{counts.outfail}}</label>
                    </div>
                    <div class="horizFlex ">
                        <button class="buttonAction primary" @click="saveAndRefresh()">Save</button>
                        <!-- <button class="buttonAction primary" @click="saveAndGetNew()">Save and New</button> -->
                        
                        <button class="buttonAction" @click="startCancel()">Cancel</button>
                    </div>
                </div>
            </div>

            

            <actionmodal ref="confirmpass"> 
                <template v-slot:body>
                    <label >Do you want to mark all of the records as passed?</label>
                    <div>
                        <button class="confirmbutton"  @click="passAll()" >Yes</button>
                        <button class="confirmbutton" @click="$refs.confirmpass.closeModal()" >Cancel</button>
                    </div>
                </template>
            </actionmodal>

            <actionmodal ref="confirmfail"> 
                <template v-slot:body>
                    <label >Do you want to mark all of the records as failed?</label>
                    <div>
                        <button class="confirmbutton"  @click="failAll()" >Yes</button>
                        <button class="confirmbutton" @click="$refs.confirmfail.closeModal()" >Cancel</button>
                    </div>
                </template>
            </actionmodal>    

            <actionmodal ref="confirmcancel"> 
                <template v-slot:body>
                    <label >Cancel Batch?</label>
                    <div>
                        <button class="confirmbuttonwide"  @click="cancelAndGetNew()" >Cancel and Get New Batch</button>
                        <button class="confirmbuttonwide"  @click="cancelAndLogout()" >Cancel and Logout</button>
                        <button class="confirmbuttonwide" @click="$refs.confirmcancel.closeModal()" >Return to Batch</button>
                    </div>
                </template>
            </actionmodal>    

            <actionmodal ref="confirmsave"> 
                <template v-slot:body>
                    <label >Save Batch?</label>
                    <div>
                        <button class="confirmbuttonwide"  @click="saveAndGetNew()" >Save and Get New Batch</button>
                        <button class="confirmbuttonwide"  @click="saveAndLogout()" >Save and Continue</button>
                        <button class="confirmbuttonwide" @click="$refs.confirmsave.closeModal()" >Return to Batch</button>
                    </div>
                </template>
            </actionmodal>    
             <actionmodal ref="confirmnewbatch"> 
                <template v-slot:body>
                    <label >Do you want to get a new Batch?</label>
                    <div>
                        <button class="confirmbuttonwide"  @click="getBatch()" >Yes Get New Batch</button>
                        <button class="confirmbuttonwide" @click="$refs.confirmsave.closeModal()" >No</button>
                    </div>
                </template>
            </actionmodal> 

            
            <actionmodal ref="nobatch"> 
                <template v-slot:body>
                    <label >There is no batch available. Please check back later.</label>
                    <div>
                        <button class="confirmbuttonwide" @click="okNoBatch()" >OK and Logout</button>
                    </div>
                </template>
            </actionmodal> 

            <imageeditor ref="imgedit" :img="selectedImageURL" @save="saveedits"/>
        </div>
  </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from '../controls/actionmodal.vue'
import RejectButton from '../actions/RejectButton.vue'
import selectbatch from '../actions/selectBatch.vue'
import {mapFields} from 'vuex-map-fields'
import style from '../../style.js'
import imageeditor from '../controls/ImageEditor.vue'
import sendemail from '../actions/sendemail.vue'
export default {
    name:'validator',
    components:{
        smallbutton,
        linkbutton,
        actionmodal,
        RejectButton,
        selectbatch,
        imageeditor,
        sendemail
    },
    props:{ 
    },
   
    mixins:[datamethods,style],
    data(){
        return{
            timeoutInMiliseconds:window.TIMEOUT_MILISECONDS,

            showNewBatch:window.SHOW_NEW_BATCH,
            imageBasePath:'',
            imageBasePath_Logo:'',

            inValidation:false,

            zoom:1,
            zoomImageSize:60,

            zoomOutLabel:"Zoom -",
            zoomInLabel:"Zoom +",
            spaceText:"  ",
            //logoFilePath:require('@/assets/NMT_MC.jpg'),
            logoFilePath:'./../' + window.SCHOOL_LOGO,
            
            passAllText:'Pass All',
            failAllText:'Fail All',

            rejectReasons:[],
            StudentRecords:[],
            batchName:'',

            filter:'',
            counts:{
                all:0,
                toreview:0,
                inpass:0,
                inmaybe:0,
                infail:0,
                outpass:0,
                outfail:0,
                selected:0
            },
            totalToReview:0,
            batchDateTime:null,

            selectedImageURL:null,
            selectedImage:null,
            selectedImageTypeID:null,
            selectedImageBadgeID:null,

            selectedRecord:null,
            pleaseWait:false
        }
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
            'batchSize',
            'logoOverride'

        ]),
        scrollBoxDiv(){
            //use the number to get the right class
            return "scrollDiv zoom" + this.zoom
        },
        logoPath(){
             if(this.logoOverride!=''){

                    return this.imageBasePath_Logo + this.logoOverride
            }else{
                return this.logoFilePath
            }
            
        },
        //filter!
        filteredRecords:function(){
            if(this.filter==''){
                return this.StudentRecords;
            }else{
                let temp = this.StudentRecords;
                if(temp!=undefined){
                    temp = temp.filter((item)=>{
                        switch (this.filter) {
                        case 'in-pass':
                            return(item.instatus=='pass')
                            break;
                        case 'in-maybe':
                            return(item.instatus=='maybe')
                            break;
                        case 'in-fail':
                            return(item.instatus=='fail')
                            break;
                        case 'out-pass':
                            return(item.outstatus=='pass')
                            break;
                        case 'out-fail':
                            return(item.outstatus=='fail')
                            break;
                        case 'toreview':
                            return(item.outstatus=='')
                            break;
                        default:
                            return(item.outstatus=='fail')
                            break;
                        }
                    })
                }
                
                return temp;
            } 
        }
    },
    mounted(){
        this.Start()
    },
    methods:{
        okNoBatch(){
            this.$refs.nobatch.closeModal();
            this.loggedIn=false
        },
      saveedits(file, url){
        this.selectedImageURL=url
        
        this.storeImage("", file, file.name)
      },
       async storeImage(filename,file, name){
            var bId=this.selectedImageBadgeID
            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('badgeId',bId);
            postParams.append('typeid',this.selectedImageTypeID);

            var response = await this.callAPI(postParams,'saveimage')
            //handle response
            if(response.Successful){
                this.refreshBatch()
            }else{
                this.responseMessagemessage = response.Message
            }
        },

      timeOut(){
          this.loggedIn=false;
      },
      resetTimer(miliseconds){
          clearTimeout(this.inactiveTime);
          this.inactiveTime = setTimeout(this.timeOut, miliseconds);
      },
        buttonActionNarrowClass(col){
            if(col==this.zoom){
                return "buttonActionNarrow bordered"
            }else{return "buttonActionNarrow"}
        },
        startSave(){
            this.$refs.confirmsave.openModal()
        },
        startCancel(){
            this.$refs.confirmcancel.openModal()
        },
        cancelAndGetNew(){
            this.cancelBatch(true)
        },
        cancelAndLogout(){
            this.cancelBatch(false)
        },
        saveAndGetNew(){
            this.saveBatch(true)
        },
        saveAndRefresh(){
            this.saveBatch(false)
        },
        Start(){
            this.inValidation=true
            this.$emit('start')
            this.getImageBasePath()
            this.getImageBasePath_Logo()
            this.getRejectReasons()
            this.getBatch()
        },
        async getImageBasePath(){
            //call api
            var response = await this.callAPI(null,'imagepath_api')
            //handle response
            if(response.Successful){
                this.imageBasePath = window.VUE_CONFIG_API + response.StringResponse
            }else{
                this.responseMessagemessage = response.Message
            }
        },
        async getImageBasePath_Logo(){
            //call api
            var response = await this.callAPI(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath_Logo =  response.StringResponse
            }else{
                this.responseMessagemessage = response.Message
            }
        },
        async getRejectReasons(){
            var response = await this.callAPI(null,'rejectreasonlist')
            //handle response
            if(response.Successful){
                this.rejectReasons=response.RejectReasonList
            }else{
                this.message = response.Message
            }
        },
        async getBatch(){
            this.$refs.confirmnewbatch.closeModal()
            this.pleaseWait=true
            //setup request
            let req = {
                totalInBatch:this.batchSize,
            }
            //call api
            var response = await this.callAPI(req,'validationbatch')
            //handle response
            if(response.Successful){
                this.StudentRecords=response.Batch.validationItems
                this.batchName = response.Batch.batchName
                this.batchDateTime = response.Batch.batchDateTime
                this.initialCounts()
                this.updateCounts()
                this.totalToReview=response.Batch.totalToValidate
                if (this.totalToReview==0) {
                    this.$refs.nobatch.openModal()
                }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false
            this.resetTimer(this.timeoutInMiliseconds);
        },
        async refreshBatch(){
            //setup request
            let req = {
                batchDateTime:this.batchDateTime,
            }
            //call api
            var response = await this.callAPI(req,'validationbatch_refresh')
            //handle response
            if(response.Successful){
                this.StudentRecords=response.Batch.validationItems
                this.batchName = response.Batch.batchName
                this.batchDateTime = response.Batch.batchDateTime
                this.initialCounts()
                this.updateCounts()
                this.totalToReview=response.Batch.totalToValidate
                if (this.totalToReview==0) {
                    this.$refs.confirmnewbatch.openModal()
                }
            }else{
                this.message = response.Message
            }

            this.resetTimer(this.timeoutInMiliseconds);
        },

        async saveBatch(getnew){
            
            var savePFOnly = !getnew
            //setup request
            let req = {
                batch:{
                    validationItems:this.StudentRecords,
                    
                },
                savePassFailOnly:savePFOnly
            }
            //call api
            var response = await this.callAPI(req,'savebatch')
            //handle response
            if(response.Successful){
                this.message="Batch Saved"
                if(getnew){
                    this.getBatch();
                }else{
                    this.refreshBatch();
                }
                this.$refs.confirmsave.closeModal()
            }else{
                this.message = response.Message
            }
        },

        async cancelBatch(getnew){
            //setup request
            let req = {
                batch:{
                    validationItems:this.StudentRecords
                }
            }
            //call api
            var response = await this.callAPI(req,'cancelbatch')
            //handle response
            if(response.Successful){
                this.message="Batch Canceled"
                if(getnew){
                    this.getBatch();
                }else{
                    this.loggedIn=false
                }
                this.$refs.confirmcancel.closeModal()
            }else{
                this.message = response.Message
            }
        },

        //image
        getImage(image){
            return this.imageBasePath + image.fileName
        },
        //pass or fail
        askPass(){
            this.$refs.confirmpass.openModal()
        },
        askFail(){
            this.$refs.confirmfail.openModal()
        },
        passAll(){
            var i;
            for(i=0;i<this.filteredRecords.length;i++){
                 var record = this.filteredRecords[i]
                 record.outstatus= 'pass';
            }
            this.updateCounts();
            this.$refs.confirmpass.closeModal()
        },
        failAll(){
            var i;
            for(i=0;i<this.StudentRecords.length;i++){
                var record = this.StudentRecords[i]
                record.outstatus= 'fail';
            }
            this.updateCounts();
            this.$refs.confirmfail.closeModal()
        },
        resetBatch(){
            var i;
            for(i=0;i<this.StudentRecords.length;i++){
                var record = this.StudentRecords[i]
                record.outstatus= null;
            }
            this.updateCounts();
        },
        setStatus(){
            
            this.updateCounts();
        },
        updateCounts(){
            this.resetTimer(this.timeoutInMiliseconds);
             var i;
            var tToReview=0, tOutPass=0, tOutFail=0
            var selct =0
            for(i=0;i<this.StudentRecords.length;i++){
                var record = this.StudentRecords[i]
                switch (record.outstatus) {
                    case 'pass':
                        tOutPass=tOutPass+1;
                        break;
                    case '':
                        tToReview=tToReview+1;
                        break;
                    case null:
                        tToReview=tToReview+1;
                        break;
                    case 'maybe':
                        tToReview=tToReview+1;
                        break;
                    case 'fail':
                         tOutFail=tOutFail+1;
                        break;
                    default:
                    break;
                }
                if(record.selected){selct=selct+1
                    console.log('sel')
                }
             }
            this.counts.toreview=tToReview;
            this.counts.outpass=tOutPass;
            this.counts.outfail=tOutFail;
            this.counts.selected=selct;
        },  
        initialCounts(){
            var i;
            var tInPass=0, tInmaybe=0, tInFail=0

            for(i=0;i<this.StudentRecords.length;i++){
                var record = this.StudentRecords[i]
                switch (record.instatus) {
                    case 'pass':
                        tInPass=tInPass+1;
                        break;
                    case 'maybe':
                        tInmaybe=tInmaybe+1;
                        break;
                    case 'fail':
                        tInFail=tInFail+1;
                        break;
                    default:
                        break;
                }
            }
            this.counts.all=this.StudentRecords.length;
            this.counts.toreview=this.StudentRecords.length;
            this.counts.inpass=tInPass;
            this.counts.inmaybe=tInmaybe;
            this.counts.infail=tInFail;
        },
            
        //filter
        ButtonFilterClass(filter){
            var outclass=''
            if(filter==this.filter){
                outclass= "buttonFilterSelected"
            }else if(filter=='in-all' && this.filter==''){
                outclass= "buttonFilterSelected"
            
            }else{
                outclass="buttonFilter"
            }
            if(filter=='out-pass'){
                outclass = outclass + ' recordPass'
            }else if(filter=='out-fail'){
                outclass = outclass + ' recordFail'
            }else if(filter=='in-fail'){
                outclass = outclass + ' recordFail'
            }
            else if(filter=='in-maybe'){
                outclass = outclass + ' recordneutral'
            }
            else if(filter=='in-pass'){
                outclass = outclass + ' recordPass'
            }
            return outclass;
        },
        setFilter(filter){
            
            if(filter=='in-all'){this.filter=''}
            else if(this.filter==filter){this.filter=''}
            else{this.filter=filter;}
        },

        //record
        recordClass(record){
            var myclass=""
            if(record.outstatus!=null){
                if(record.outstatus=="pass"){
                    myclass= "horizFlex record recordPass"
                }else if(record.outstatus=="fail"){
                     myclass= "horizFlex record recordFail"
                }else{
                     myclass= "horizFlex record recordMaybe"
                }
            }else{
                 myclass= "horizFlex record recordUnselected"
            }
            if(record.instatus=='pass'){myclass=myclass + ' recordPassIn'}
            else if(record.instatus=='maybe'){myclass=myclass + ' recordMaybeIn'}
            else if(record.instatus=='fail'){myclass=myclass + ' recordFailIn'}

    
            return  myclass
            
        },
        //record image
        recordImageClass(image){
            var rclass = ""
            if(image.enlarged){rclass= "recordImageEnlarged"}
            else{rclass= "recordImage"}

            //add color border
            if(image.instatus!=null){
                if(image.instatus=="pass"){
                    rclass=rclass + " passborder"
                }else if(image.instatus=="fail"){
                    rclass=rclass + " failborder"
                }else{
                    rclass=rclass + " maybeborder"
                }
            }
            return rclass

        },
        toggleImageSize(image,img,item){
            console.log('el ' + img)
            image.enlarged = !image.enlarged
            if(image.enlarged){
                this.selectImage(image,img,item)
            }
        },
        selectImage(image,img,item){
            this.selectedRecord=item
            this.selectedImage=image
            this.selectedImageTypeID=image.badgeImageTypeId
            this.selectedImageURL= img.src
            this.selectedImageBadgeID = item.badgeId
        },
        //zoom
        setzoom(col){
            this.zoom = col
            
        },
        zoomIn(){
            //if(this.zoom <6){this.zoom = this.zoom + 1}
            if(this.zoomImageSize<120){this.zoomImageSize+=20}
           
            document.documentElement.style.setProperty('--image-size',this.zoomImageSize + 'px');
        },
        zoomOut(){
            if(this.zoomImageSize >20){this.zoomImageSize = this.zoomImageSize - 20}
           
            document.documentElement.style.setProperty('--image-size',this.zoomImageSize + 'px');
        }
    }

}
</script>

<style>
:root{
    --text-color:rgb(58, 54, 54)!important;
    --light-text-color:white!important;
    --button-color:#075190!important;
    --pass-color:rgb(120, 195, 120)!important;
    --fail-color:rgb(221, 161, 161)!important;
    --maybe-color:rgb(236, 236, 142)  ;
    --unselected-color:lightgray!important;
    --pass-out-color:rgb(97, 159, 230)!important;
    --fail-out-color:gray!important;
    --image-size:60px;
}
.validationDiv{
     height:90%;
    display:grid;
    grid-template-columns:1fr 4fr;
    grid-template-rows:100px auto;
    grid-template-areas: 
        "header header header"
        "center center center"
        "footer footer footer";
    font-size:x-large;
}
.filterVar{
    display:flex;
    padding:0px;
    flex-direction: row;
    justify-content:space-between;
    width:97%;

}

.mainDiv{
    grid-area: center;
    background-color:gray;
    /* background-image:linear-gradient(white,gray); */
    padding:10px;
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top:15px;
}
header{
    grid-area:header;
    padding:10px; 
}
.vallogo{
    height:100px;
    
}
.lookup{
  height:40px;
  margin:5px;
  font-size:inherit;
}
/* filter */
.filterlabel{
    margin-top:10px;
}
.buttonFilterWide{
  padding:5px;
   border-radius:5px;
   border:solid;
   border-width: 1px;
   font-size:large;
    width:120px;
    height:50px;
    margin:5px;
}

.buttonFilter{
  padding:5px;
   border-radius:5px;
   border:solid;
   border-width: 1px;
   font-size:large;
    width:80px;
    height:50px;
    margin:5px;
}
.buttonFilterSelected{
  padding:5px;
   border-radius:5px;
   border:solid;
   border-width: 3px;
   font-size:large;
    width:80px;
    height:50px;
    margin:5px;
    font-weight:bold;
    border-color:var(--button-color);
}
.buttonImage{
  height:40px;
}
.actionbuttontext{
  font-size:medium;
  margin-top:0px;
  margin-left:5px;
}
/* scrolldiv */
.scrollDiv{
    height:55vh;
    width:95%;
    padding:10px;
    overflow-x:auto;
    overflow-y:scroll;
    background-color:gray;
    display:grid;
}
.zoom1{
    grid-template-columns:1fr;
}
.zoom2{
    grid-template-columns:1fr 1fr;
}
.zoom3{
    grid-template-columns:1fr 1fr 1fr;
}
.zoom4{
    grid-template-columns:1fr 1fr 1fr 1fr;
}
.zoom5{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
}
.zoom6{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
}

/* record */
.record{
  padding:10px;
  border-style: none;
  border-radius:5px;
  justify-content:space-between;
  align-items: center;
  margin-bottom:10px;
  margin-right:10px;
}
.recordPassIn{
  border-left:10px solid var(--pass-color);
}
.recordMaybeIn{
    border-left:10px solid var(--maybe-color);
}
.recordFailIn{
    border-left:10px solid var(--fail-color);

}
.recordPass{
  background-color:var(--pass-color);
}
.recordMaybe{
  background-color:var(--maybe-color);
}
.recordUnselected{
  background-color:var(--unselected-color);
}
.recordFail{
  background-color:var(--fail-color);
}
.recordPass_Out{
  background-color:var(--pass-out-color);
}
.recordFail_Out{
  background-color:var(--fail-out-color);
}

.recordneutral{
  background-color: rgb(235, 235, 146) ;
}
.recordText{
    font-size:medium;
  margin:0px;
}
.recordTextsmall{
  font-size:medium;
  margin:0px;
}
/* record image */
.imageAndCaption{
  border-radius:5px;
  /* border:var(--border-color);
  border-width: 1px;
  border-style: solid; */
  padding:5px;
  background-color:white;
}
.imageCaption{
  font-size:small;
  margin:0px;
}
.recordImage{
  height:var(--image-size);
  transition: height 1s;
}
.recordImageEnlarged{
  height:150px;
}
.recordImage:hover{
  height:150px;
}
.passborder{
    border:3px solid var(--pass-color);
}
.maybeborder{
    border:3px solid var(--maybe-color);
}
.failborder{
    border:3px solid var(--fail-color);
}

/* bar */
.bar{
  height:30px;
  width:168px;
  margin:0px 0px 10px 0px;
}
.redbar{
  background-color: var(--fail-color) ;
}
.greenbar{
  background-color: var(--pass-color) ;
}
.yellowbar{
  background-color: rgb(235, 235, 146) ;
}

/* actions */

.buttonAction{
    padding:5px;
    border-radius:20px;
    border:none;
    font-size:inherit;
    min-width:100px;
    height:50px;
    margin:5px;
}
.buttonActionNarrow{
    padding:5px;
    border-radius:20px;
    border:none;
    font-size:inherit;
    width:40px;
    height:50px;
    margin:5px;
}
.bordered{
    border:1px solid black;
}
.primary{
    background-color:var(--button-color);
    color:var(--light-text-color);
}
/* totals */
.totallabel{
  margin-right:10px;
  margin-top:10px;
  color:white;
  font-weight:bold;
}

/* confirmation */
.confirmbutton{
  padding:5px;
   border-radius:20px;
   border:none;
   font-size:inherit;
    width:110px;
    height:50px;
    margin:5px;
    background-color:var(--button-color);
    color:white;
}
.confirmbuttonwide{
  padding:5px;
   border-radius:20px;
   border:none;
   font-size:inherit;
    width:300px;
    height:50px;
    margin:5px;
    background-color:var(--button-color);
    color:white;
}
footer{
    grid-area:footer;
    padding:10px;

    display:flex;
    flex-direction:column;
    text-align: center;
    font-size: small;
    
}
.footerlink:link{
    color:var(--text-color);
}
.footerlink:visited{
    color:var(--text-color);
}
.footerlink:hover{
    color:var(--button-color);
}

.lighttext{
    color:var(--light-text-color);
}
.marginright{
    margin-right:10px;
}
.marginleft{
    margin-left:30px;
}
.mediumtext{
    font-size: medium;
}
.colimg{
    height:20px;
}
</style>